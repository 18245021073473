import { env } from "process";
import { notFound } from "next/navigation";
const { print } = require("graphql/language/printer");
export default async function fetchApolloData(query, vars) {
	const reqheaders = new Headers();
	reqheaders.append("Content-Type", "application/json");
	reqheaders.append(
		"X-Secure-Environment",
		`${process.env.NEXT_PUBLIC_APOLLOSEC}`
	);
	const graphReq = JSON.stringify({
		query: print(query),
		variables: vars,
	});
	const reqOpts = {
		method: "POST",
		headers: reqheaders,
		body: graphReq,
		next: { revalidate: 5 },
	};

	const endpoint =
		process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ??
		"https://apollo-pre.nmas.live/graphql";
	return await fetch(endpoint, reqOpts)
		.then((res) => {
			switch (res.status) {
				case 200:
					// console.log("sc:", 200);
					return res.json();
				case 400:
					// console.log("sc:", 400);
					throw new Error(`Bad request ${res?.status}`);
				case 404:
					// console.log("sc:", 404);
					notFound();
					break;
				case 500:
					// console.log("sc:", 500);
					throw new Error(`SERVER ERROR ${res?.status}`);
				case 502:
					// console.log("sc:", 502);
					throw new Error(`SERVER TIMEOUT ${res?.status}`);
			}
		})
		.then((data) => {
			if (typeof data === "object" && data != null) {
				if (Object.keys(data)?.length > 0) {
					// console.log(data);

					if ("errors" in data) {
						// console.log("have errors");
						data?.errors.forEach((error) => {
							console?.error(error?.message, error?.path);
							console?.error(error?.extensions?.stacktrace);
						});
						return {
							...data,
							errors: data?.errors,
						};
					} else if ("data" in data) {
						// console.log("------------- saul goodman ----------------");
						// console.log(data);

						return data;
					}
				}
			} else {
				// console.log(data);
				throw new Error("Data llegó null");
			}
		})
		.catch((err) => {
			return {
				data: null,
				errors: err,
			};
		});
}
