"use client";
import { ReactNode, useEffect } from "react";
import styles from "@/app/styles/Molecules/NCarousel.module.scss";
import Icon from "@/app/components/atoms/Icon/Icon";
import Carousel from "./Logic/Carousel";


type Props = {
	id: string;
	children: Array<ReactNode>
	moveItems?: number;
};

const Demo = ({ id, children, moveItems = 0 }: Props ) => {

	useEffect(() => {
		const INIT = new Carousel();
		INIT.init({
			arrowNext: `#next-${id}`,
			arrowPrevious: `#previus-${id}`,
			moveItems,
			track: `#track-${id}`,
		});
	}, [id, moveItems]);


	return (
		<div data-type="corousel" className={styles.carousel}>
			<button id={`previus-${id}`} className={`${styles.carousel__arrow} ${styles["carousel__arrow--previous"]}`}>
				<Icon name="arrowPrev"/>
			</button>
			<button id={`next-${id}`} className={`${styles.carousel__arrow} ${styles["carousel__arrow--next"]}`}>
				<Icon name="arrowNext"/>
			</button>

			<div data-type="viewBox" className={styles.carousel__viewBox}>
				<div id={`track-${id}`} className={styles.carousel__track}>
					{
						children.map((child, index) => (
							<div key={`carousel-item-${index}`} className={styles.carousel__item}>
							{child}
						</div>
						))
					}
				</div>
			</div>
		</div>
	);
};

export default Demo;
