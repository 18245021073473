"use client";
import { useState, useEffect, Suspense, Children } from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import parse from "html-react-parser";
import { ToggleMuteProvider } from "@/app/context/MutePlayerContext";
import { CountDownProvider } from "@/app/context/CardCountContext";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { Thumbnail } from "@/app/types/Thumbnail.type";
import { Term } from "@/app/types/Term.type";
import { secondsToISO8601 } from "@/app/helpers/timeHelpers/timeHelpers";
import styles from "@/app/styles/Blocks/BlockDetailVideo.module.scss";
import SocialShareButtons from "@/app/components/molecules/SocialShareButtons/SocialShareButtons";
import EndCard from "@/app/components/molecules/EndCard/EndCard";
import Breadcrumb from "@/app/components/molecules/Breadcrumb/Breadcrumb";
import InteractiveLink from "@/app/components/organisms/BloqueHeroGrid/InteractiveLink";
import RelatedPlaylist from "./RelatedPlaylist";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const BoxAds = dynamic(
	() => import("@/app/components/atoms/AdContainer/BoxAds")
);

declare const window: WindowWithDataLayer;

type GenericVideo = {
	title: string;
	titleTag?: string;
	id: string;
	summary: string;
	duration: number;
	date: string;
	uploadDate: string;
	cmsId: string;
	channel: string;
	path: string;
	contentUrl: String;
	term: [Term];
	terms: [Term];
	thumbnail: Thumbnail;
	isVideo: boolean;
	categoryId: number;
	priorizedIds: [number];
	image: string;
	dateTime?: string;
};

const VideoPlayerList = ({
	videos,
	title,
	isNotStatic,
	fullVersion,
	position,
	showTitle = "",
	isFullVersion = false,
	isProgram = false,
}: any) => {
	const { mainVideo, relatedVideo } = videos ?? {};
	const {
		mainVideo: { hasProgramAd, hasVideoAd },
	} = videos ?? {};
	const [currentVideo, setCurrentVideo] = useState<GenericVideo>(mainVideo); // ACÁ SE DEFINE LA VARIABLE currentVideo (estado inicial)
	const [prevVod, setPrevVod] = useState<GenericVideo>(mainVideo);
	const [isPlaying, setPlaying] = useState<string>(mainVideo?.cmsId);
	const [videoList, setVideoList] = useState<any>(relatedVideo ?? []);
	const [showEndCard, setShowEndCard] = useState(false);
	const descriptionRender = currentVideo?.summary ?? undefined;
	const { summary } = currentVideo;
	const pathName = usePathname();
	const [currentPath, setCurrentPath] = useState(pathName ?? "/");
	const isPeP = isFullVersion; // pathName?.includes('/por-el-planeta/');
	const replaceHtmlChars = /<[^>]*>/gi;
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: undefined,
		contenidosBloque: undefined,
		countWidget: undefined,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: undefined,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: "LinkText",
		tituloBloque: undefined,
	};
	const bannerValidations =
		!pathName?.includes("/por-el-planeta/") &&
		!pathName?.includes("/nmas-focus/");
	const updatedVod = (prevVod: any, newCurrent: any) => {
		if (prevVod?.cmsId !== newCurrent?.cmsId) {
			setPrevVod(prevVod);
			setCurrentVideo(newCurrent);
		}
	};
	const videoIsPlaying = (element: any) => {
		const videoId = element?.cmsId || "";
		return isPlaying === videoId;
	};
	/**
	 * Se encarga de hacer el cambio de video en la playlist después
	 * del endCard. Se ejecuta cuando termina un video
	 * @param {*} index
	 * @param {*} playerInstance
	 */
	const handleAutoChangeVideo = (index: any, playerInstance: any) => {
		if (isNotStatic) {
			setCurrentPath(videoList[index + 1]?.path);
			window?.history?.pushState(
				window?.history?.state,
				"",
				videoList[index + 1]?.path
			);
		}
		// End handling router history
		// Send pageview on video change
		const nextVideo = videoList[index + 1];
		// SET isPlaying
		setPlaying(nextVideo?.cmsId);
		updatedVod(currentVideo, {
			cmsId: nextVideo?.cmsId,
			title: nextVideo?.title,
			titleTag: nextVideo?.titleTag,
			summary: nextVideo?.summary,
			date: nextVideo?.date,
			dateModified: nextVideo?.dateModified,
			image: nextVideo?.thumbnail?.imageUrl?.webp,
			publisher: nextVideo?.publisher,
			alt: nextVideo?.alt,
			keywords: nextVideo?.keywords,
			term: nextVideo?.term || nextVideo?.terms,
			path: nextVideo?.path,
			duration: nextVideo?.duration,
		});

		const sessionData = window.sessionStorage.getItem("sessionData");
		let prevPath;
		if (sessionData) {
			const sDataParsed = JSON.parse(sessionData);
			prevPath = sDataParsed.prevPath;
		}
		window.dataLayer.push({
			event: "pageView",
			author: "N+ video",
			etiquetas: nextVideo?.term?.map((term: Term) => term.name) ?? undefined,
			page_location: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
				nextVideo?.path
			}`,
			page_path: nextVideo?.path,
			page_title: nextVideo?.titleTag ?? nextVideo?.title,
			page_previous: prevPath,
			secccion: nextVideo?.path,
			tipoContenido: "VOD",
			fecha_publicacion_texto: nextVideo?.dateTime,
		});
	};

	const handleChangeToFisrtVideo = (index: any, playerInstance: any) => {
		if (isNotStatic) {
			setCurrentPath(videoList[index + 1]?.path);
			window?.history?.pushState(
				window?.history?.state,
				"",
				videoList[index + 1]?.path
			);
		}
		// SET isPlaying
		const nextVideo = videoList[index + 1];
		// SET isPlaying
		setPlaying(nextVideo?.cmsId);

		updatedVod(currentVideo, {
			cmsId: nextVideo?.cmsId,
			title: nextVideo?.title,
			summary: nextVideo?.summary,
			date: nextVideo?.date,
			dateModified: nextVideo?.dateModified,
			image: nextVideo?.thumbnail?.imageUrl?.webp,
			publisher: nextVideo?.publisher,
			alt: nextVideo?.alt,
			keywords: nextVideo?.keywords,
			term: nextVideo?.term || nextVideo?.terms,
			path: `${nextVideo?.path}/`,
			duration: nextVideo?.duration,
		});
		const sessionData = window.sessionStorage.getItem("sessionData");
		let prevPath;
		if (sessionData) {
			const sDataParsed = JSON.parse(sessionData);
			prevPath = sDataParsed.prevPath;
		}

		window.dataLayer.push({
			event: "pageView",
			author: "N+ video",
			etiquetas: nextVideo?.term?.map((term: Term) => term.name) ?? undefined,
			page_location: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
				nextVideo?.path
			}`,
			page_path: nextVideo?.path,
			page_title: title,
			page_previous: prevPath,
			secccion: nextVideo?.path,
			tipoContenido: "VOD",
		});
	};

	const prevOnEndedVideo = () => {
		setShowEndCard(true);
	};

	const onEndedEndCardCount = () => {
		if (videoList?.length > 1) {
			const index = videoList?.findIndex((videoAtIndex: GenericVideo) => {
				return videoAtIndex?.cmsId === currentVideo.cmsId;
			});
			const playerInstance = window?.VideoSDK?.AMP.getInstance("playerVod");
			if (index < videoList?.length - 1) {
				handleAutoChangeVideo(index, playerInstance);
				setShowEndCard(false);
			} else if (index === videoList?.length - 1 || !videoList) {
				handleChangeToFisrtVideo(-1, playerInstance);
				setShowEndCard(false);
			}
		}
	};

	useEffect(() => {
		return () => {
			window?.VideoSDK?.AMP?.off("ended");
		};
	}, [videoList]);

	useEffect(() => {
		const ldJsons = document?.querySelectorAll('[type="application/ld+json"]');
		const ldJsonTag = Array.from(ldJsons).filter((script) => {
			if (
				script?.textContent?.includes("VideoObject") &&
				!script?.textContent?.includes("ItemList")
			) {
				return script;
			}
			return null;
		});
		const re = /(<([^>]+)>)/g;
		const cleanDescription = currentVideo?.summary?.replaceAll(re, "");
		const headTag = document.head;

		if (ldJsonTag?.length > 0) {
			const ldObj = JSON.parse(ldJsonTag[0]?.textContent ?? "{}");
			ldObj.url = `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
				currentVideo?.path
			}`;
			ldObj.name = currentVideo?.title;
			ldObj.embedUrl = `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
				/\/+$/,
				""
			)}/preview-video/?idvideo=${currentVideo?.cmsId}`;
			ldObj.uploadDate = currentVideo?.uploadDate;
			ldObj.thumbnailUrl = [
				currentVideo?.thumbnail?.imageUrl?.webp ?? currentVideo?.image,
			];
			ldObj.description = cleanDescription;
			ldObj.contentUrl = currentVideo?.contentUrl;
			ldObj.duration = secondsToISO8601(currentVideo?.duration);

			// Elimina el script tag del ld+json del VideoObject
			ldJsonTag[0]?.remove();
			// Crea un nuevo script, le inserta el objeto json y lo inseta en <head>
			const newLdJsonScript = document.createElement("script");
			newLdJsonScript.setAttribute("type", "application/ld+json");
			newLdJsonScript.setAttribute("id", `vID-${currentVideo?.cmsId}`);
			newLdJsonScript.textContent = JSON.stringify(ldObj);
			headTag?.appendChild(newLdJsonScript);
		}
		// Actualiza el title tag
		if (!isProgram) {
			document.title = currentVideo?.titleTag ?? "";

			document
				.querySelectorAll('[property="og:title"]')[0]
				?.setAttribute("content", currentVideo?.titleTag ?? "");
		}

		document
			.querySelectorAll('[name="description"]')[0]
			?.setAttribute("content", cleanDescription);
		document
			.querySelectorAll('[property="og:image"]')[0]
			?.setAttribute(
				"content",
				currentVideo?.thumbnail?.imageUrl?.webp ?? currentVideo?.image
			);
		document
			.querySelectorAll('[rel="canonical"]')[0]
			?.setAttribute(
				"href",
				`${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
					currentVideo?.path
				}`
			);
		const storage = sessionStorage;
		const etiquetas = currentVideo?.term?.map((item) => item.name);
		let sessionData = storage.getItem("sessionData") ?? "";
		let to: any = null;
		if (sessionData) {
			const sdObj = {
				...JSON.parse(sessionData),
				etiquetas,
				prevPath: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
					currentVideo?.path
				}`,
				prevElemntType: "Button",
			};
			storage.setItem("sessionData", JSON.stringify(sdObj));
		} else {
			to = setTimeout(() => {
				sessionData = storage.getItem("sessionData") ?? "";
				const sdObj = {
					...JSON.parse(sessionData),
					etiquetas,
					prevPath: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
						currentVideo?.path
					}`,
					prevElemntType: "Button",
				};
				storage.setItem("sessionData", JSON.stringify(sdObj));
			}, 125);
		}
		return () => clearTimeout(to);
	}, [currentVideo, isProgram]);
	return (
		<>
			<CountDownProvider>
				<ToggleMuteProvider>
					<div className={styles.video__wrapper}>
						<Breadcrumb
							themeColor="dark"
							getLasted={true}
							currentPath={currentPath}
							specialPath={currentVideo?.title}
							hasPadding
						/>
						{bannerValidations && hasProgramAd && (
							<BoxAds withAd isDark typeAd={"banner"} />
						)}
						{showTitle && (
							<header className={styles?.video__wrapper__header}>
								<h1>{showTitle}</h1>
							</header>
						)}
						<div className={styles.video__container}>
							<div
								className={`
							${styles.video__grid_container}
							${isPeP ? styles.video__grid_container__pep : ""}
						`}
							>
								<div className={styles.video__cell}>
									<div className={styles.video__player}>
										<div className={styles.video__player__wrapper}>
											<div
												style={
													showEndCard && videoList?.length > 1
														? {}
														: { display: "none" }
												}
											>
												<EndCard
													actualVod={currentVideo}
													onEndedVideo={onEndedEndCardCount}
													videoList={videoList}
													showEndCard={showEndCard}
													setShowEndCard={setShowEndCard}
												/>
											</div>

											<PlayerVodV2
												idVideo={currentVideo.cmsId}
												prevVod={prevVod.cmsId}
												onVideoEnded={() => prevOnEndedVideo()}
												autoplay
												mute={false}
											/>
										</div>
									</div>
									<div className={styles?.video__cell__videoInfo}>
										<h1 className={styles.video__cell__detail_title}>
											{currentVideo.title}
										</h1>
										<div className={styles.video__cell__detail_subtitle}>
											{descriptionRender !== undefined
												? parse(descriptionRender)
												: ""}
										</div>
										<p className={styles.video__cell__detail_time}>
											<time dateTime={currentVideo.date}>
												{currentVideo.date}
											</time>
										</p>
										<ul className={styles.video__cell__terms}>
											{currentVideo.term !== undefined &&
												Children.toArray(
													currentVideo?.term?.map((item: any, i: any) => {
														return (
															<li key={`term-${i}-${item?.name}`}>
																<InteractiveLink
																	url={item?.url}
																	title={item?.name}
																	blockData={{
																		...blockDataLayer,
																		content_name: item?.name,
																		content_type: "botón",
																	}}
																	cardPosition={undefined}
																	isLink={true}
																>
																	{item?.name}
																</InteractiveLink>
															</li>
														);
													})
												)}
											{currentVideo.terms !== undefined &&
												Children.toArray(
													currentVideo?.terms?.map((item: any, i: any) => {
														return (
															<li key={`term-${i}-${item?.name}`}>
																<InteractiveLink
																	url={item?.url}
																	title={item?.name}
																	blockData={blockDataLayer}
																	cardPosition={position}
																>
																	{item?.name}
																</InteractiveLink>
															</li>
														);
													})
												)}
										</ul>
										<div className={`${styles["video__cell__wrapperSocial"]}`}>
											<SocialShareButtons
												title={currentVideo.title}
												description={summary?.replace(replaceHtmlChars, "")}
												link={currentPath}
												isVariantVideoPlayerList={true}
											/>
										</div>
									</div>
								</div>
								{!fullVersion && !isPeP && (
									<div className={styles.video__cell_list}>
										<Suspense fallback={<div>Cargando...</div>}>
											<RelatedPlaylist
												setVideoList={setVideoList}
												videoList={videoList}
												currentVideo={currentVideo}
												mainVideo={mainVideo}
												updatedVod={updatedVod}
												setCurrentPath={setCurrentPath}
												setPlaying={setPlaying}
												isNotStatic={isNotStatic}
												videoIsPlaying={videoIsPlaying}
												title={title}
												showEndCard={showEndCard}
												setShowEndCard={setShowEndCard}
												canonical={`${process.env.NEXT_PUBLIC_BASE_URL?.replace(
													/\/+$/,
													""
												)}${currentPath}`}
											/>
										</Suspense>
									</div>
								)}
							</div>
						</div>
					</div>
					{!fullVersion && isPeP && (
						<div className={styles.video__container}>
							<div
								className={`
								${styles.video__cell_list}
								${styles.video__cell_list__pep}
							`}
							>
								<Suspense fallback={<div>Cargando...</div>}>
									<RelatedPlaylist
										isPeP={isPeP}
										setVideoList={setVideoList}
										videoList={videoList}
										currentVideo={currentVideo}
										mainVideo={mainVideo}
										updatedVod={updatedVod}
										setCurrentPath={setCurrentPath}
										setPlaying={setPlaying}
										isNotStatic={isNotStatic}
										videoIsPlaying={videoIsPlaying}
										title={title}
									/>
								</Suspense>
							</div>
							<div className={styles.video__link}>
								<InteractiveLink
									url={"/por-el-planeta/programas/por-el-planeta/"}
									title={"Ver más"}
									blockData={blockDataLayer}
									cardPosition={position}
								>
									Ver más
								</InteractiveLink>
							</div>
						</div>
					)}
				</ToggleMuteProvider>
			</CountDownProvider>
		</>
	);
};
export default VideoPlayerList;
